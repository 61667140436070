export const CreateComment = `mutation MyMutation($ParentCommentID: Int, $Content: String!, $VideoID: Int!) {
    createComment(input: {ParentCommentID: $ParentCommentID, Content: $Content, VideoID: $VideoID}) {
        CognitoUserID
        UserName
        CommentID
        Content
        CreatedAt
        ParentCommentID
        Status
        VideoID
        Deleted
    }
}`

export const EditComment = `mutation MyMutation($CommentID: Int!, $Content: String!) {
    editComment(input: {CommentID: $CommentID, Content: $Content}) {
        CognitoUserID
        CommentID
        Content
        CreatedAt
        ParentCommentID
        Status
        VideoID
    }
}`

export const GetComment = `query MyQuery($VideoID: Int!) {
    getComment(VideoID: $VideoID) {
        UserName
        CommentID
        CognitoUserID
        VideoID
        ParentCommentID
        Content
        CreatedAt
        Status
        Deleted
    }
}`

export const DeleteComment = `mutation MyMutation($CommentID: Int!) {
    deleteComment(input: {CommentID: $CommentID}) {
        UserName
        CommentID
        CognitoUserID
        VideoID
        ParentCommentID
        Content
        CreatedAt
        Status
        Deleted
    }
}`

export const ListCommentAdmin = `query MyQuery($Limit: Int, $Page: Int, $Filter: Int, $Search: String) {
    getCommentListAdmin(input: {Limit: $Limit, Page: $Page, Filter: $Filter, Search: $Search}) {
        Count {
            All
            Mine
            Pending
            Approved
            Spam
            Trash
        }
        Items {
            CommentID
            UserID
            UserName
            DisplayName
            Email
            Avatar {
                bucket
                region
                key
                order
            }
            VideoID
            VideoTitle
            VideoThumb {
                bucket
                region
                key
                order
            }
            VideoTotalComment
            Content
            CreatedAt
            Status
            Deleted
            ParentCommentID
            ParentCommentName
        }
    }
}`;

export const UpdateCommentAdmin = `mutation MyMutation($CommentID: Int, $Content: String, $Deleted: Int, $Status: Int) {
    updateCommentAdmin(input: { CommentID: $CommentID, Content: $Content, Deleted: $Deleted, Status: $Status }) {
      Message
      Status
    }
}`

export const DeleteCommentAdmin = `mutation MyMutation($CommentID: Int!) {
    deleteCommentAdmin(CommentID: $CommentID) {
        Status
        Message
    }
}`;

export const GetCommentAdmin = `query MyQuery($CommentID: Int) {
    getCommentAdmin(CommentID: $CommentID) {
        Content
        UserName
        Email
        Status
        Deleted
    }
}`