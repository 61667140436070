import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Link, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonTemplate } from '../button/button-template';
import { TextFieldTemplate } from '../input/textfield-template';
import { CreateComment, UpdateCommentAdmin } from '../../lib/graphql-command/comment-command';
import { API, graphqlOperation } from 'aws-amplify';

const useStyle = makeStyles(theme => ({
    dialogTitle: {
        position: 'relative'
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: theme.palette.grey[500]
    },
}));

export const Reply = ({open, setOpen, comment, listCommentAPI}) => {
    console.log(comment)
    const inputRef = useRef();
    const [disabled, setDisabled] = useState(false)

    const handleReply = () => {
        setDisabled(true)
        API.graphql(graphqlOperation(UpdateCommentAdmin, {
            Status: 1,
            Deleted: 0,
            Content: comment.Content,
            CommentID: comment.CommentID
        }))
        .then(result => {})
        .catch(err => console.log(err));

        API.graphql(graphqlOperation(CreateComment, {
            Content: inputRef.current.value,
            VideoID: comment.VideoID,
            ParentCommentID: comment.CommentID
        }))
        .then(result => {
            console.log('child', result.data)
            API.graphql(graphqlOperation(UpdateCommentAdmin, {
                Status: 1,
                Deleted: 0,
                Content: result.data?.createComment?.Content,
                CommentID: result.data?.createComment?.CommentID
            }))
            .then(result => {
                setDisabled(false)
                inputRef.current.value = ''
                listCommentAPI()
                setOpen(false)
                window.scrollTo(0, 0)
            })
            .catch(err => console.log(err));
        })
        .catch(err => console.log(err))
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle className={useStyle().dialogTitle}>
                <Box pt={5} px={5} fontWeight='fontWeightBold'>
                    <Box pr={2} display='inline-block'>Reply to</Box>
                    <Link href={`${process.env.GATSBY_USER_SITE}mlnpstar/${comment.DisplayName}`} target='blank'>
                        #{comment.DisplayName}
                    </Link>
                </Box>
                <IconButton onClick={() => setOpen(false)} className={useStyle().closeButton}>
                    <CloseIcon fontSize='small' />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box pt={5}>
                    <TextFieldTemplate
                        label='Reply comment'
                        multiline
                        rows={5}
                        inputRef={inputRef}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Box px={10} pb={5}>
                    <Grid container direction='row' spacing={2}>
                        <Grid item>
                            <ButtonTemplate
                                textDecoration='none'
                                color='primary'
                                variant='outlined'
                                size='small'
                                borderradius='25px'
                                changetextcolor='#000'
                                onClick={() => setOpen(false)}
                            >Cancel</ButtonTemplate>
                        </Grid>
                        <Grid item>
                            <ButtonTemplate
                                textDecoration='none'
                                color='primary'
                                variant='contained'
                                size='small'
                                borderradius='25px'
                                changetextcolor='#fff'
                                onClick={handleReply}
                                disabled={disabled}
                            >{comment.Status === 0 ? 'Approve and Reply' : 'Reply'}</ButtonTemplate>
                        </Grid>
                    </Grid>
                </Box>
            </DialogActions>
        </Dialog>
    )
}