import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import { TabController } from '../../components/comments/tab-controller';
import { TabPanelController } from '../../components/comments/tab-panel-controller';
import { ListCommentAdmin } from '../../lib/graphql-command/comment-command';

let defaultCondition = {
    Limit: 25,
    Page: 1,
    Filter: 0,
    Search: null
};

const Comments = () => {
    const [tab, setTab] = useState(0);
    const [condition, setCondition] = useState(defaultCondition);
    const [count, setCount] = useState({});
    const [comments, setComments] = useState([]);
    const [disabled, setDisabled] = useState(false);

    const listCommentAPI = () => {
        API.graphql(graphqlOperation(ListCommentAdmin, {
            Limit: condition.Limit,
            Page: condition.Page,
            Filter: condition.Filter,
            Search: condition.Search
        }))
        .then(result => {
            const commentResult = result.data?.getCommentListAdmin;
            setCount(commentResult?.Count);
            setComments(commentResult?.Items);
            setDisabled(false);
        })
        .catch(err => {
            console.log(err);
            setDisabled(false);
        });
    }

    useEffect(() => {
        setDisabled(true);
        listCommentAPI();
    }, [condition]);

    console.log(disabled);

    return (
        <Box>
            <Typography variant="h4">Comments</Typography>
            <TabController {...{ tab, setTab, count, setCondition }} />
            {disabled ?
                <Grid container justify='center'>
                    <Box pt={20}>
                        <CircularProgress color='primary' size={25} thickness={5} />
                    </Box>
                </Grid>
            :
                <TabPanelController {...{ tab, count, comments, listCommentAPI, condition, setCondition }} />
            }
        </Box>
    )
}

export default Comments;
