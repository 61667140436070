import React, { useState } from 'react';
import { Avatar, Box, Grid, Link, makeStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Typography } from '@material-ui/core';
import Constants from '../../constants/constants';
import { green, red } from '@material-ui/core/colors';
import API, { graphqlOperation } from '@aws-amplify/api';
import { navigate } from 'gatsby';
import { DeleteCommentAdmin, UpdateCommentAdmin } from '../../lib/graphql-command/comment-command';
import { tabList } from './tab-controller';
import { Reply } from './reply';
import _ from 'lodash'

const useStyle = makeStyles(() => ({
    avatarWrap: {
        width: '50px',
        height: '50px',
    },
    thumbnailWrap: {
        height: '70px',
        position: 'relative'
    },
    thumbnailStyle: {
        height: '100%',
        margin: '0 auto'
    },
    actionWrap: {
        height: '5px'
    },
    link: {
        fontSize: '0.8rem',
        color: '#000'
    },
    tableRowWrap: {
        '& .actionList': {
            display: 'none',
            '& a': {
                marginRight: '10px',
                cursor: 'pointer'
            }
        },
        '&:hover': {
            '& .actionList': {
                display: 'block'
            }
        }
    },
    content: {
        maxWidth: '500px'
    },
    warning: {
        color: red[700]
    },
    success: {
        color: green[700]
    }
}));

const tableHeadList = ['Author', 'Comment', 'Video thumbnail', 'In response to', 'Submitted on']

export const TabPanelController = ({ tab, count, comments, listCommentAPI, condition, setCondition }) => {
    const { actionWrap, avatarWrap, content, link, success, tableRowWrap, thumbnailStyle, thumbnailWrap, warning } = useStyle();
    const [replyComment, setReplyComment] = useState();
    const [openReply, setOpenReply] = useState(false);

    const handleUpdate = (record, value, item) => {
        API.graphql(graphqlOperation(UpdateCommentAdmin, _.pick({
            ...item,
            [record]: value
        }, ['CommentID', 'Content', 'Deleted', 'Status'])))
        .then(result => {
            console.log(result);
            listCommentAPI();
        })
        .catch(err => console.log(err));
    }

    const handleDelete = (item) => {
        API.graphql(graphqlOperation(DeleteCommentAdmin, { CommentID: item.CommentID }))
            .then(result => {
                console.log(result);
                listCommentAPI();
            })
            .catch(err => console.log(err));
    }

    const handleSpam = (item) => {
        API.graphql(graphqlOperation(UpdateCommentAdmin, _.pick({
            ...item,
            Status: 2,
            Deleted: 0
        }, ['CommentID', 'Content', 'Deleted', 'Status'])))
            .then(result => {
                console.log(result);
                listCommentAPI();
            })
            .catch(err => console.log(err));
    }

    const handleReply = item => {
        setReplyComment(item);
        setOpenReply(true);
    }

    const getActions = (item) => {
        const { Status } = item;
        if (tab === 5) return (
            <Grid container className='actionList'>
                <Link className={`${warning} ${link}`} onClick={() => handleSpam(item)}>Spam</Link>
                <Link className={link} onClick={() => handleUpdate('Deleted', 0, item)}>Restore</Link>
                <Link className={`${warning} ${link}`} onClick={() => handleDelete(item)}>Delete Permanently</Link>
            </Grid>
        )

        if (Status === 2) return (
            <Grid container className='actionList'>
                <Link className={`${success} ${link}`} onClick={() => handleUpdate('Status', 0, item)}>Not Spam</Link>
                <Link className={`${warning} ${link}`} onClick={() => handleDelete(item)}>Delete Permanently</Link>
            </Grid>
        )

        return (
            <Grid container className='actionList'>
                {Status === 0 ?
                    <Link className={`${success} ${link}`} onClick={() => handleUpdate('Status', 1, item)}>Approve</Link>
                    :
                    <Link className={`${success} ${link}`} onClick={() => handleUpdate('Status', 0, item)}>Unapprove</Link>
                }
                <Link className={link} onClick={() => handleReply(item)}>Reply</Link>
                <Link className={link} onClick={() => navigate(`/edit-comment/${item.CommentID}`)}>Edit</Link>
                <Link className={`${warning} ${link}`} onClick={() => handleSpam(item)}>Spam</Link>
                <Link className={`${warning} ${link}`} onClick={() => handleUpdate('Deleted', 1, item)}>Trash</Link>
            </Grid>
        )
    }

    const getTotalRows = () => {
        const tabItem = tabList.filter(item => item.key === tab)[0];
        return count[tabItem?.label];
    }

    const handleChangePage = (event, newPage) => {
        setCondition(condition => ({
            ...condition,
            Page: newPage+1
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setCondition(condition => ({
            ...condition,
            Limit: parseInt(event.target.value, 10)
        }));
    }

    const tableBody = () => {
        if (comments.length === 0) return (
            <Box py={5}>
                <Typography>No comment</Typography>
            </Box>
        )
        return (
            comments.map(item => (
                <TableRow key={item.CommentID} className={tableRowWrap}>
                    <TableCell>
                        <Grid container>
                            <Grid item>
                                <Box mr={5}>
                                    <Avatar src={item.Avatar?.key ? `${process.env.GATSBY_CLOUDFRONT_DOMAIN}${item.Avatar.key}` : Constants.defaultAvatar} className={avatarWrap} />
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant='body1' color='primary'>{decodeURI(item.DisplayName)}</Typography>
                                <Typography variant='body2'>{item.Email}</Typography>
                            </Grid>
                        </Grid>
                    </TableCell>
                    <TableCell className={content}>
                        {item.ParentCommentID && <Box pb={2}>
                            <Typography variant='caption'>
                                <Grid container>
                                    <Box pr={2}>Reply to</Box>
                                    <Link href={`${process.env.GATSBY_USER_SITE}mlnpstar/${item.ParentCommentName}`} target='blank'>
                                        {item.ParentCommentName}
                                    </Link>
                                </Grid>
                            </Typography>
                        </Box>}
                        <Box pb={2}>{item.Content}</Box>
                        <Box pb={2} className={actionWrap}>{getActions(item)}</Box>
                    </TableCell>
                    <TableCell>
                        <Box className={thumbnailWrap} display='flex'>
                            <img src={item.VideoThumb?.key ? `${process.env.GATSBY_CLOUDFRONT_DOMAIN}${item.VideoThumb.key}` : Constants.defaultThumbnail} alt='video-thumbnail' className={thumbnailStyle} />
                        </Box>
                    </TableCell>
                    <TableCell>
                        <Typography variant='body2'>{item.VideoTitle}</Typography>
                        <Link href={`${process.env.GATSBY_USER_SITE}video/${item.VideoID}`} target='blank'>
                            <Typography variant='caption'>View post</Typography>
                        </Link>
                    </TableCell>
                    <TableCell>
                        <Typography variant='body2'>{item.CreatedAt}</Typography>
                    </TableCell>
                </TableRow>
            ))
        )
    };

    return (
        <Box p={10}>
            {replyComment && <Reply {...{open: openReply, setOpen: setOpenReply, comment: replyComment, listCommentAPI}}/>}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableHeadList.map(item => (
                                <TableCell key={item} align='center'>{item}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableFooter>
                <TableRow>
                    <TablePagination
                        count={getTotalRows() || 0}
                        rowsPerPage={condition.Limit}
                        component="div"
                        page={condition.Page-1}
                        rowsPerPageOptions={[25, 50]}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </TableRow>
            </TableFooter>
        </Box>
    )
}

// CommentID, UserID, UserName, DisplayName, Email, VideoID, VideoTitle, VideoThumb, VideoTotalComment, Content, CreatedAt, Status, ParentCommentID