import React, { useRef } from 'react';
import { Grid, InputAdornment, Paper, Tab, Tabs, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const tabList = [
    { key: 0, label: 'All' },
    { key: 1, label: 'Mine' },
    { key: 2, label: 'Pending' },
    { key: 3, label: 'Approved'},
    { key: 4, label: 'Spam' },
    { key: 5, label: 'Trash' }
];

export const TabController = ({ tab, setTab, count, setCondition }) => {
    const searchRef = useRef()
    const handleChangeCondition = (item, value) => {
        if(item === 'Filter') setTab(value);

        setCondition(condition => ({
            ...condition,
            [item]: value,
            Page: 1
        }))
    }

    return (
        <Grid container justify='space-between'>
            <Grid item>
                <Paper>
                    <Tabs
                        value={tab}
                        indicatorColor='primary'
                        textColor='primary'
                        onChange={(event, newValue) => handleChangeCondition('Filter', newValue)}
                    >
                        {tabList.map(item => (
                            <Tab key={item.key} label={`${item.label} (${count[item.label] || 0})`} />
                        ))}
                    </Tabs>
                </Paper>
            </Grid>
            <Grid item>
                <TextField
                    color='primary'
                    variant='outlined'
                    size='small'
                    placeholder='Search'
                    inputRef={searchRef}
                    onChange={(e) => handleChangeCondition('Search', e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                onClick={e => {
                                    searchRef.current.value = ''
                                    handleChangeCondition('Search', '')
                                }}
                            >
                                <CloseIcon style={{cursor: 'pointer'}} />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
        </Grid>
    )
}